import React, { Component } from 'react';
import question from './assets/img/question.png';

export default class Row extends Component{
    render(){
        return (
            <div className='mt-ebitda-row mt-ebitda-table-row'>
                <li>{this.props.category}</li>
                <li><span>Minimum</span><div className='bubble'>{this.props.min}x</div></li>
                <li className='mt-question'><span>You</span>?</li>
                <li><span>Maximum</span><div className='bubble'>{this.props.max}x</div></li>
            </div>
        )
    }
}