import React from 'react';

export default ({
    name, 
    options, 
    disabled = false, 
    onChange = (e) => e.preventDefault
}) => {
    return (
        <div className={`mt-select-wrapper ${disabled ? 'disabled' : ''}`}>
            <select name={name} disabled={disabled} onChange={onChange}>
                { options.map(option => <option value={option[0]}>{option[1]}</option>) }
            </select>
        </div>
    )
}